<template>
  <v-card
    v-if="result"
    :loading="loading"
  >
    <v-card-title class="d-flex justify-space-between">
      <span>{{'ga_report.users.title'| t}}</span>

      <v-tooltip top>
        <template #activator="{on}">
          <v-btn v-on="on" icon @click="loadReportData(true)">
            <v-icon class="fa fa-sync-alt"></v-icon>
          </v-btn>
        </template>

        <div>{{'ga_report.action.force_update'| t}}</div>
      </v-tooltip>
    </v-card-title>

    <v-card-subtitle>
      <div>{{'ga_report.users.subtitle'| t}}</div>
      <div>{{'data.updated_at'| t}}: {{result.updated_at}}</div>
    </v-card-subtitle>

    <v-divider></v-divider>

    <v-card-text>
      <line-chart
        v-if="chartReady"
        style="height: 300px"
        :chartData="chartData"
        :options="options"
      ></line-chart>
    </v-card-text>
  </v-card>
</template>

<script lang="babel" type="text/babel">
import lineChart from 'components/charts/lineChart.vue'
export default {
  data: () => ({
    result: null,
    loading: false,
  }),
  created() {
    this.loadReportData()
  },
  methods: {
    async loadReportData(force = false) {
      this.loading = true
      try {
        const result = await this.$api.collection.gaReportApi.users({
          force: force === true ? 1 : 0,
        })
        this.result = {
          data: result.data.reverse(),
          updated_at: result.updated_at,
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    dailyData() {
      if(!this.result) return null
      if(!Array.isArray(this.result.data)) return null
      return this.result.data
    },
    dates() {
      if(!this.dailyData) return []
      return this.dailyData.map(item => item.date)
    },
    chartReady() {
      if(!this.dailyData) return false
      return this.dailyData.length > 0
    },
    chartData() {
      return {
        labels: this.dates,
        datasets: [
          {
            label: this.$t('ga_report.data.users'),
            borderColor: '#38c172',
            fill: false,
            data: this.dailyData.map(item => item.users),
          },
        ],
      }
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false
      }
    },
  },
  components: {
    lineChart,
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
